import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonIcon, IonSearchbar, IonList, IonItem, IonLabel, IonListHeader, IonCard, IonCardContent, IonRow, IonCol, IonLoading, IonTabs, IonTabBar, IonTabButton, IonBadge, IonRouterOutlet, IonBackButton, IonGrid } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../data/AppContext';
import { setAsistencias } from '../data/state'
import { refresh, camera, compass } from 'ionicons/icons';
import { AsistenciaItem } from '../data/DataModels'
import { RouteComponentProps } from 'react-router';

import { trash, close } from 'ionicons/icons';
import { IonFab, IonFabButton, IonImg, IonActionSheet, IonText } from '@ionic/react';

import { usePhotoGallery } from '../hooks/usePhotoGallery';


interface AsistenciaPageProps extends RouteComponentProps<{
  id: string;
}> { }

const Asistencia: React.FC<AsistenciaPageProps> = ({ match }) => {

  const { state, dispatch } = useContext(AppContext);
  const { asistencias } = state;
  const [showLoading, setShowLoading] = useState(false);
  const [asistencia, setAsistencia] = useState<AsistenciaItem | undefined>(undefined);
  const { photos, takePhoto } = usePhotoGallery();

  useEffect(() => {

    //TODO: request just the current 
    console.log('use effect');

    console.log('asistencias', asistencias);

    if (asistencias.length == 0) {
      updateData();
    } else {
      findCurrent();
    }

  }, []);

  const findCurrent = (_asistencias?: AsistenciaItem[]) => {

    console.log('find current', _asistencias);

    let id = 0;
    try {
      id = parseInt(match.params.id);
    } catch (error) {

    }

    console.log('id', match.params.id, id);

    let asistencia;
    if (_asistencias) {
      asistencia = _asistencias.find(i => i.id == id);
      console.log('_ass', asistencia)
    } else {
      asistencia = asistencias.find(i => i.id == id);
    }


    setAsistencia(asistencia);

    console.log(asistencia);
  }

  const updateData = async () => {

    console.log("updateData");

    if (showLoading) {
      return;
    }

    setShowLoading(true);

    fetch('https://solweb.sol.com.py/asistencias-api/get-asistencias')
      .then(response => {

        if (response.status != 200) {
          throw new Error('Error en descargar los datos');
        }

        return response.json();

      })
      .then(data => {
        console.log('data', data);
        setAsistencias(dispatch, data.data);
        findCurrent(data.data);
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (

    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Asistencia nº: {asistencia && asistencia.id}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={updateData}>
              <IonIcon icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton onClick={() => { takePhoto() }}>
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>

        {asistencia &&
          <div>
            <IonCard key={asistencia.id}>
              <IonItem>
                <IonLabel>{asistencia.cliente}</IonLabel>
                <IonButton fill="outline" slot="end" onClick={() => { }}><IonIcon icon={compass} /></IonButton>
              </IonItem>

              <IonCardContent>
                <b>{asistencia.plan}</b>
                <p>
                  {asistencia.mensaje}
                </p>
              </IonCardContent>
            </IonCard>
            <IonGrid>
              <IonRow>
                {photos.map((photo, index) => (
                  <IonCol size="6" key={index}>
                    <IonImg src={photo.webviewPath} />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </div>
        }

        {!asistencia &&

          <div className="ion-padding">
            <p>
              No ha sido posible cargar la asistencia. Por favor, intente nuevamente.
            </p>

            <IonButton fill="outline" onClick={() => updateData()}>Volver a cargar</IonButton>
          </div>
        }

        <IonLoading
          isOpen={showLoading}
          message={'Cargando...'}
        />

      </IonContent>
    </IonPage>
  );
};

export default Asistencia;
