import React, { createContext, useReducer } from 'react';
import { initialState, AppState, reducer } from './state'


export interface AppContextState {
  state: AppState;
  dispatch: React.Dispatch<any>;
}

export const AppContext = createContext<AppContextState>({
  state: initialState,
  dispatch: () => undefined
});

export const AppContextProvider: React.FC = (props => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {state, dispatch};
  
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
});

export const AppContextConsumer = AppContext.Consumer