import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AppContext } from './data/AppContext';
import { AppContextProvider } from './data/AppContext';

import Home from './pages/Home';
import Login from './pages/Login';
import Asistencia from './pages/Asistencia';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {

  const { state, dispatch } = useContext(AppContext);

  setupConfig({
    mode: 'ios'
  })

  useEffect(() => {

    console.log('Calling use Effect');

    var url = 'https://solweb.sol.com.py/asistencias-api/login';
    
    fetch(url)

  }, [state]);

  return (
    <AppContextProvider>
      <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/home" component={Home} exact={true} />
              <Route path="/login" component={Login} />
              <Route path="/logout" render={() => {
                dispatch({
                  type: 'set-is-logged-in',
                  loggedIn: false
                });
                return <Redirect to="/login" />
              }} />
              <Route exact path="/" render={() => (state.isLoggedIn ? <Redirect to="/home" /> : <Redirect to="/login" />)} />
              <Route path="/view/:id" component={Asistencia} />
            </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AppContextProvider>
  )
};

export default App;
