import { AsistenciaItem } from "./DataModels";
import React from "react";


export interface IAppState {
    isLoggedIn: boolean;
    asistencias: AsistenciaItem[];
};

export const initialState = {
    isLoggedIn: false,
    asistencias: []
};

export interface DispatchObject {
    [key: string]: any,
    type: string
};

export const reducer = (state: IAppState, action: any): IAppState => {

    switch (action.type) {
        case "set-is-logged-in": {
            return { ...state, isLoggedIn: action.loggedIn }
        }
        case 'set-asistencias':
            return { ...state, asistencias: action.asistencias };
    }
    return state;
};


export const setIsLoggedIn = (dispatch: React.Dispatch<any>, loggedIn:boolean) => {
    dispatch({
        type: 'set-is-logged-in',
        loggedIn: loggedIn
      });
}

export const setAsistencias = (dispatch: React.Dispatch<any>, asistencias:AsistenciaItem[]) => {
    dispatch({
        type: 'set-asistencias',
        asistencias: asistencias
      });
}


export type AppState = ReturnType<typeof reducer>;
