import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonIcon, IonSearchbar, IonList, IonItem, IonLabel, IonListHeader, IonCard, IonCardContent, IonRow, IonCol, IonLoading, IonTabs, IonTabBar, IonTabButton, IonBadge, IonRouterOutlet } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../data/AppContext';
import { shareAlt, chatboxes, logoTwitter } from 'ionicons/icons';
import { setAsistencias } from '../data/state'
import { refresh } from 'ionicons/icons';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Login from './Login';

const Home: React.FC<RouteComponentProps> = ({history}) => {

  const { state, dispatch } = useContext(AppContext);
  const { asistencias } = state;
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {

    console.log('use effect');

    updateData();

  }, []);


  const updateData = async () => {

    console.log("updateData");

    if (showLoading) {
      return;
    }

    setShowLoading(true);

    fetch('https://solweb.sol.com.py/asistencias-api/get-asistencias')
      .then(response => {

        if (response.status != 200) {
          throw new Error('Error en descargar los datos');
        }

        return response.json();

      })
      .then(data => {
        console.log('data', data);
        setAsistencias(dispatch, data.data);
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (

    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sol Asistencia</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={updateData}>
              <IonIcon icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonSearchbar></IonSearchbar>

        {asistencias.map(item => (

          <IonCard key={item.id}>
            <IonItem>
              <IonLabel>{item.cliente}</IonLabel>
              <IonButton onClick={e => {
                e.preventDefault();
                history.push('/view/' + item.id);
              }} fill="outline" slot="end">View</IonButton>
            </IonItem>

            <IonCardContent>
              <b>{item.plan}</b>
              <p>
                {item.mensaje}
              </p>
            </IonCardContent>
          </IonCard>
        ))}


        <IonLoading
          isOpen={showLoading}
          message={'Cargando...'}
        />

      </IonContent>
    </IonPage>
  );
};

export default Home;
