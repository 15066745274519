import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonRow, 
  IonCol, 
  IonButton, 
  IonList, 
  IonItem, 
  IonLabel, 
  IonInput, 
  IonText,
  IonLoading
} from '@ionic/react';
import './Login.scss';
import React, { useContext, useState, FormEvent } from 'react';
import {AppContext} from '../data/AppContext';
import { RouteComponentProps } from 'react-router';
import {setIsLoggedIn} from '../data/state'

interface OwnProps extends RouteComponentProps {}

const Login: React.FC<RouteComponentProps> = ({history}) => {

  const { state, dispatch } = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState<String | Boolean>(false);

  const submitAction = (e: FormEvent) => {

    e.preventDefault();

    setFormSubmitted(true);

    setUsernameError(!username);
    setPasswordError(!password);

    if(!username || !password) {
      return;
    }

    setShowLoading(true);

    setTimeout(() => {

      setIsLoggedIn(dispatch, true);

      setShowLoading(false);

      history.replace('/home');

      //setFormErrorMessage('Usuário y/o contraseña inválidos.');
      //setShowLoading(false);
    }, 2000);

  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sol Asistencia</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        
      <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form noValidate onSubmit={submitAction}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Nombre usuário</IonLabel>
              <IonInput name="username" type="text" value={username} spellCheck={false} autocapitalize="off"
                onIonChange={e => setUsername(e.detail.value!)} required>
              </IonInput>
            </IonItem>

            {formSubmitted && usernameError && <IonText color="danger">
              <p className="ion-padding-start">
                Nombre de usuário es obligatório.
              </p>
            </IonText>}

            <IonItem>
              <IonLabel position="stacked" color="primary">Contraseña</IonLabel>
              <IonInput name="password" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)} required>
              </IonInput>
            </IonItem>

            {formSubmitted && passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                La contraseña es obligatória.
              </p>
            </IonText>}

          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Login</IonButton>
            </IonCol>
          </IonRow>
        </form>

        {formSubmitted && formErrorMessage && <IonText color="danger">
              <p className="ion-padding-start">
                {formErrorMessage}
              </p>
            </IonText>}

        <IonLoading
        isOpen={showLoading}
        message={'Cargando...'}
      />

      </IonContent>
    </IonPage>
  );
};

export default Login;